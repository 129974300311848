/*
 * @Description:部门
 * @Author: xiawenlong
 * @Date: 2021-04-22 20:13:24
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-04-22 20:14:20
 */
export default {
  deptSelect: '/course/manage/depts/select', // 部门下拉列表
  deptTree: '/course/manage/depts/tree', // 查询机构下的部门列表树
}
