<!--
 * @Description: 邀请学员
 * @Author: xiawenlong
 * @Date: 2021-04-22 10:06:14
 * @LastEditors: xiawenlong
 * @LastEditTime: 2024-06-05 09:57:42
-->
<template>
  <el-dialog :close-on-click-modal="false" class="student-invite" :visible.sync="dialogVisible">
    <div class="close-icon" @click="dialogVisible = false">
      <i class="el-icon-close"></i>
    </div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="链接" name="link">
        <p>复制邀请链接，粘贴至微信等，快速邀请员工加入书院</p>
        <div
          v-clipboard:copy="link"
          v-clipboard:success="onCopySuccess"
          v-clipboard:error="onCopyError"
          class="copy"
        >
          <p :title="link">{{ link }}</p>
          <div class="btn">
            复制链接
          </div>
        </div>
      </el-tab-pane>
      <!-- <el-tab-pane label="二维码" name="qr">
        <p>保存二维码，粘贴至微信等，快速邀请员工加入书院</p>
        <div class="qrcode">
          <img :src="inviteInfo.qrCode" alt="" />
        </div>
        <div class="btn" @click="copyQrCode">复制二维码</div>
      </el-tab-pane> -->
      <el-tab-pane label="书院邀请码" name="code">
        <p>分享书院邀请码，在首页使用邀请码</p>
        <div
          v-clipboard:copy="codeCopyText"
          v-clipboard:success="onCopySuccess"
          v-clipboard:error="onCopyError"
          class="copy"
        >
          <p>{{ inviteInfo.code }}</p>
          <div class="btn">
            复制书院邀请码
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>
<script>
import { studentInvite } from '@/api/student'
import { mapGetters, createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('user')
import to from 'await-to'
export default {
  name: 'StudentInvite',
  data() {
    return {
      activeName: 'link',
      dialogVisible: false,
      inviteInfo: {},
      link: '',
      codeCopyText: '',
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    ...mapState(['collegeInfo']),
  },
  methods: {
    show() {
      this.dialogVisible = true
      this.getiInviteInfo()
    },
    async getiInviteInfo() {
      const [res, err] = await to(studentInvite())
      if (err) return this.$message.warning(err.msg)
      this.inviteInfo = res.data
      this.link = `${this.userInfo.nickName}邀请你加入${this.collegeInfo.organizationName} ，点击链接立即申请加入，进行在线学习：`
      // this.codeCopyText = `${this.collegeInfo.organizationName}书院名称邀请您的加入，邀请码：${this.inviteInfo.code}。加入方式：微信搜索小程序“明商道”中加入学院`
      this.codeCopyText = `${this.inviteInfo.code}`
      this.link = this.link += this.inviteInfo.link
    },
    async copyQrCode() {
      const img = document.querySelector('.qrcode img')
      const setToClipboard = blob => {
        // eslint-disable-next-line no-undef
        const data = [new ClipboardItem({ [blob.type]: blob })]
        return navigator.clipboard.write(data)
      }
      try {
        const response = await fetch(img.src)
        const blob = await response.blob()
        console.log(blob)
        setToClipboard(blob)
        this.onCopySuccess()
      } catch (error) {
        this.onCopyError()
      }
    },
    onCopySuccess() {
      this.$message.success('复制成功')
    },
    onCopyError() {
      this.$message.success('复制失败')
    },
  },
}
</script>
<style lang="scss" scoped>
.student-invite {
  ::v-deep.el-dialog {
    position: relative;
    width: 560px;
    height: 350px;
    background: #ffffff;
    border-radius: 0;
    color: #4d4e5d;
    // &::before {
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   content: '';
    //   width: 560px;
    //   height: 6px;
    //   background: #ff7b33;
    // }
    .close-icon {
      z-index: 99;
      cursor: pointer;
      position: absolute;
      right: 24px;
      top: 10px;
      font-size: 25px;
      font-weight: bold;
      color: #8c8c8c;
    }
    .el-dialog__header {
      display: none;
    }
    .el-tabs__header {
      margin-bottom: 26px;
      padding-top: 10px;
    }
    .el-dialog__body {
      padding: 0;
    }
    .el-tabs__item {
      font-size: 18px;
      font-weight: 400;
      color: #4d4e5d;
      margin-bottom: 5px;
      padding: 0 18px;
      &.is-active {
        color: #ff7b33;
        font-weight: 500;
      }
      &.is-top:nth-child(2) {
        padding-left: 36px;
      }
    }
    .el-tabs__active-bar {
      height: 4px;
    }
    .el-tabs__content {
      padding: 0 30px;
    }
    #pane-link {
      > p {
        font-size: 14px;
        font-weight: Normal;
        margin-bottom: 14px;
      }
      .copy {
        width: 500px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #ff7b33;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        p {
          flex: 1;
          overflow: hidden;
          @extend %one-line-text;
          padding: 0 4px;
        }
        .btn {
          cursor: pointer;
          width: 120px;
          height: 40px;
          background: #ff7b33;
          font-size: 14px;
          font-weight: Normal;
          color: #ffffff;
          text-align: center;
        }
      }
    }
    #pane-qr {
      text-align: center;
      p {
        margin-bottom: 13px;
      }
      .qrcode {
        margin: 0 auto;
        width: 142px;
        height: 144px;
        margin-bottom: 18px;
        border: 1px solid #eee;
        img {
          @extend %width-height;
        }
      }
      .btn {
        cursor: pointer;
        margin: 0 auto;
        width: 120px;
        height: 40px;
        line-height: 40px;
        background: #ff7b33;
        font-size: 14px;
        font-weight: Normal;
        color: #ffffff;
      }
    }
    #pane-code {
      > p {
        margin-bottom: 20px;
      }
      .copy {
        width: 240px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #ff7b33;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        p {
          flex: 1;
          overflow: hidden;
          @extend %one-line-text;
          padding: 0 4px;
          text-align: center;
        }
        .btn {
          cursor: pointer;
          width: 120px;
          height: 40px;
          background: #ff7b33;
          font-size: 14px;
          font-weight: Normal;
          color: #ffffff;
          text-align: center;
        }
      }
    }
  }
}
</style>
